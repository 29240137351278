import { FiGlobe, FiHome, FiTrendingUp, FiUserPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import home from "../assets/home.png";
// import mac from "../assets/machome.png";
import investhome from "../assets/investhome.png";
import Benefits from "./cards/Benefits";
// import ProtectCard from "./cards/ProtectCard";
import Sequence from "./cards/Sequence";
import "./Home.css";
import { FiBriefcase, FiDollarSign, FiEye, FiShuffle } from "react-icons/fi";

function Home() {
  return (
    <div>
      <div className="landing">
        <div className="container pt-5 pb-5">
          <div className="row ">
            <div className="col-md-6 mt-auto mb-auto pe-4 title-holder">
              <h1
                className="h1 pb-2"
                style={{
                  fontWeight: 700,
                  fontStyle: "semi-bold",
                  fontSize: 50,
                }}
              >
                The Best Way To Invest in Real Estate
              </h1>
              <p className="font-weight-normal pb-2">
                Investify Africa helps you achieve Financial Freedom by
                investing in Vacant Land, Residential, Industrial and Commercial
                Real Estate Projects in shares.
              </p>
              <p className="font-weight-normal pb-2">
                Earn 8% - 15% on Rental Income. <br />
                Earn over 50% on Land Banking.
              </p>
              <div>
                <a
                  href="https://app.investify.africa/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    className="btn btn-success me-2 p-3 mb-4"
                    style={{ borderRadius: 10, fontWeight: 600 }}
                  >
                    Create a Demo Account
                  </button>
                </a>
              </div>
            </div>
            <div className="text-center col-md-6">
              <img
                src={home}
                alt="Investify Mobile"
                className="img-fluid"
                style={{ height: 600 }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Section: How does it work */}

      <div className="container text-center mb-5" style={{ marginTop: 100 }}>
        <h2
          style={{
            fontWeight: 700,
            fontStyle: "semi-bold",
            fontSize: 40,
            marginBottom: 32,
          }}
        >
          How does it work?
        </h2>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 col-sm-12 p-4">
              <Sequence
                icon={<FiUserPlus />}
                title="Register"
                para="Register with your email address to become a member "
              />
            </div>
            <div className="col-md-4 col-sm-12 p-4">
              <Sequence
                icon={<FiGlobe />}
                title="Explore"
                para=" Browse through different Real Estate Projects, find the one that suits you "
              />
            </div>
            <div className="col-md-4 col-sm-12 p-4">
              <Sequence
                icon={<FiTrendingUp />}
                title="Invest"
                para="Invest in shares, own a share of the real Estate Project"
              />
            </div>
          </div>
        </div>
        <Link to="how-it-works">
          <div>
            <button
              className="btn btn-success me-2 p-3"
              style={{ borderRadius: 10, fontWeight: 600 }}
            >
              Learn More
            </button>
          </div>
        </Link>
      </div>

      {/* The 4 types of Real Estate */}
      <div className="landing3 benefit-block" style={{ marginTop: 100 }}>
        <div className="container pt-5 pb-5">
          <div className="new-hold">
            <h2
              style={{
                fontWeight: 700,
                fontStyle: "semi-bold",
                fontSize: 45,
              }}
            >
              A New Way to Make Real Estate Investments Work For Your Pocket
            </h2>
            <p className="font-weight-normal pb-3">
              Investify Africa makes it possible for individual investors to
              earn dividends from real estate investments—without having to buy,
              manage, or finance any properties themselves. You also get to
              diversify your real estate portfolio across different asset
              classes, asset types, geographical markets, and more.
            </p>
          </div>

          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6 col-sm-12 p-4">
                <Benefits
                  icon={<FiHome />}
                  title="Residential Real Estate"
                  para="Any property used for residential purposes. Examples include single-family homes, duplexes, townhouses, and multifamily residences with fewer than five individual units."
                />
              </div>
              <div className="col-md-6 col-sm-12 p-4">
                <Benefits
                  icon={<FiHome />}
                  title="Land"
                  para="Includes undeveloped property, vacant land, and agricultural land, Land can include anything that's on the ground, which means that buildings, trees, and water that are a part of land are an asset."
                />
              </div>
              <div className="col-md-6 col-sm-12 p-4">
                <Benefits
                  icon={<FiHome />}
                  title="Industrial Real Estate"
                  para="Any property used for manufacturing, production, distribution, storage, and research and development. Examples include factories, power plants, and warehouses."
                />
              </div>
              <div className="col-md-6 col-sm-12 p-4">
                <Benefits
                  icon={<FiHome />}
                  title="Commercial Real Estate"
                  para="Any property used exclusively for business purposes, such as apartment complexes, gas stations, grocery stores, hospitals, hotels, offices, parking facilities, restaurants, shopping centers, stores, and theaters."
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Additional Info */}

      {/* Investment Journey */}
      <div className="container pt-5 mb-5" style={{ marginTop: 100 }}>
        <div className="row">
          <div className="col-md-6 text-center mb-5">
            <img
              src={investhome}
              alt="Investify Mobile"
              className="img-fluid"
              style={{ height: 600 }}
            />
          </div>
          <div className="col-md-6 mb-auto mt-auto text-center">
            <h2 style={{ fontWeight: 700, fontSize: 40 }}>
              Start your Real Estate investment journey with as low as <br />
              <span
                style={{
                  backgroundColor: "#008080",
                  color: "#ffffff",
                  borderRadius: 10,
                  padding: 5,
                  lineHeight: 2.0,
                }}
              >
                ₦50,000 per share
              </span>
            </h2>
            <p>
              With Investify Africa everyone can now participate in Real Estate
              Investments
            </p>

            <div>
              <a
                href="https://app.investify.africa/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="btn btn-success me-2 p-3 mb-4"
                  style={{ borderRadius: 10, fontWeight: 600 }}
                >
                  Get Started
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose Us */}
      <div className="landing3 benefit-block">
        <div
          className="container pt-5 pb-5"
          // style={{ marginTop: 50 }}
        >
          <div className="new-hold">
            <h2
              style={{
                fontWeight: 700,
                fontStyle: "semi-bold",
                fontSize: 45,
              }}
            >
              Why Invest with Us?
            </h2>
            <p className="font-weight-normal pb-3">
              Investify Africa makes it possible for individual investors to
              earn dividends from real estate investments—without having to buy,
              manage, or finance any properties themselves.
            </p>
          </div>

          <div className="row">
            <div className="col-md-4 p-3">
              <Benefits
                icon={<FiUserPlus />}
                title="Free Registration"
                para="Becoming a member of Investify Africa is free of charge, once 
                you Register you can start investing and earning cashflows or capital gains"
              />
            </div>
            <div className="col-md-4 p-3">
              <Benefits
                icon={<FiBriefcase />}
                title="Property Management"
                para="Investify helps you optimize the value of your assets by maximizing operational 
                performance and enriching tenant experiences through innovative property management."
              />
            </div>
            <div className="col-md-4 p-3">
              <Benefits
                icon={<FiTrendingUp />}
                title="Build Wealth"
                para="The best way to hedge your capital against inflation is by 
                investing in real estate, whether it is for capital gains or cashflow, 
                Investify is your best option for wealth creation.  "
              />
            </div>
            <div className="col-md-4 p-3">
              <Benefits
                icon={<FiDollarSign />}
                title="Liquidity"
                para="Real estate investments are considered non-liquid assets because it 
                can take months for a person to receive cash from the sale, 
                with investify you can easily sell your shares from your dashboard."
              />
            </div>
            <div className="col-md-4 p-3">
              <Benefits
                icon={<FiEye />}
                title="Transparency"
                para="Get access to proper disclosure of financial information, such as a company's 
                audited financial reports and funds surrounding the various fees that'll be charged, 
                with updates on each asset you purchase."
              />
            </div>
            <div className="col-md-4 p-3">
              <Benefits
                icon={<FiShuffle />}
                title="Diversification"
                para=" In order to mitigate the risk of your portfolio overall and to ensure ongoing and steady 
                growth, you need to diversify your real estate portfolio across different 
                asset categories."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
