function Sequence(props) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            padding: 13,
            marginBottom: 28,
            backgroundColor: "#fff",
            borderRadius: 24,
            height: 84,
            width: 84,
            fontSize: 34,
            position: "relative",
            alignItems: "center",
            color: "#008080",
          }}
        >
          {props.icon}
        </div>
        <h5 style={{ fontSize: 20, fontWeight: "700" }}>{props.title}</h5>
        <p>{props.para}</p>
      </div>
    </>
  );
}

export default Sequence;
