import Accordion from "react-bootstrap/Accordion";

function Faqs() {
  return (
    <>
      <div className="container pt-5 pb-5 ">
        <div className="text-center pt-5 pb-5">
          <h1
            className="h1"
            style={{ fontWeight: 700, fontStyle: "semi-bold", fontSize: 50 }}
          >
            Some Frequently Asked Questions
          </h1>
          <p className="font-weight-normal text-muted pb-3">
            Get almost every answer to that question you have always wanted to
            ask on how it works.
          </p>
        </div>
        <div className="container">
          <Accordion className="">
            <Accordion.Item eventKey="0" className="mb-3 border border-success">
              <Accordion.Header>What is Investify Africa?</Accordion.Header>
              <Accordion.Body>
                Investify Africa is an alternate investment in real estate,
                which allow it's users to buy shares of real estate for the
                primary purpose of investing in income-generating real estate,
                such as residential homes, shopping malls, offices, warehouses,
                agricultural lands, etc
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="mb-3 border border-success">
              <Accordion.Header>
                How does Investify Africa generate profits?
              </Accordion.Header>
              <Accordion.Body>
                Investify Africa generates profits through Cash Flow(Rent) and
                Capital Gains(Disposal of property)
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className="mb-3 border border-success">
              <Accordion.Header>
                What are the benefits of investing with Investify Africa?
              </Accordion.Header>
              <Accordion.Body>
                Cash Flow <br /> Inflation Hedging <br /> Capital Appreciation{" "}
                <br />
                Portfolio Diversification <br /> Liquidity
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className="mb-3 border border-success">
              <Accordion.Header>
                How do i invest with Investify Africa?
              </Accordion.Header>
              <Accordion.Body>
                Create a free account with your email address, sign in and
                explore to select the best investment that suits you.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className="mb-3 border border-success">
              <Accordion.Header>
                Who can invest with Investify Africa?
              </Accordion.Header>
              <Accordion.Body>
                Everyone. Individual investors of all ages, both in the Nigeria
                and Africa as a whole.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default Faqs;
