import { Link } from "react-router-dom";
import logo from "../assets/investifylogo.png";

function Footer() {
  return (
    <>
      <div className="b-example-divider mt-5"></div>

      <div className="container">
        <footer className="row py-3 my-3 border-top pt-5">
          <div className="col-md-4 col-sm-12 mb-5">
            <Link
              to="/"
              className="d-flex align-items-center mb-3 link-dark text-decoration-none"
            >
              <img src={logo} alt="Investify Africa" style={{ width: 250 }} />
            </Link>
            <p className="text-muted">
              Investify Africa helps you achieve Financial Freedom by investing
              in Vacant Land, Residential, Industrial and Commercial real Estate
              Projects in shares.
            </p>
          </div>
          <div className="col-md-2 col-sm-6 mb-4">
            <h5>About Us</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link to="how-it-works" className="nav-link p-0 text-muted">
                  How it works
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="faqs" className="nav-link p-0 text-muted">
                  FAQs
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="" className="nav-link p-0 text-muted">
                  Reviews
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-sm-6 mb-4">
            <h5>Business</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link to="" className="nav-link p-0 text-muted">
                  Refer and Earn
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="" className="nav-link p-0 text-muted">
                  Sell Property
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="" className="nav-link p-0 text-muted">
                  Partners
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-sm-6 mb-4">
            <h5>Legal</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <Link to="" className="nav-link p-0 text-muted">
                  Security
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="" className="nav-link p-0 text-muted">
                  Terms of use
                </Link>
              </li>
              <li className="nav-item mb-2">
                <Link to="" className="nav-link p-0 text-muted">
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-2 col-sm-6 mb-4">
            <h5>Social Media</h5>
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a
                  href="https://www.facebook.com/investifyafrica"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link p-0 text-muted"
                >
                  Facebook
                </a>
              </li>
              <li className="nav-item mb-2">
                <a
                  href="https://www.instagram.com/investifyafrica/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link p-0 text-muted"
                >
                  Instagram
                </a>
              </li>
              <li className="nav-item mb-2">
                <a
                  href="https://twitter.com/investifyafrica"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link p-0 text-muted"
                >
                  Twitter
                </a>
              </li>
              <li className="nav-item mb-2">
                <a
                  href="https://wa.me/message/UPUPPRSASM7UL1"
                  className="nav-link p-0 text-muted"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  WhatsApp
                </a>
              </li>
            </ul>
          </div>
        </footer>
        <p className="text-muted text-center">
          &copy; 2020 Oga Titus Enterprises
        </p>
      </div>
    </>
  );
}

export default Footer;
