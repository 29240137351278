import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import { Container, Nav } from "react-bootstrap";
import logo from "../assets/investifylogo.png";

function Header() {
  return (
    <div className="mb-5">
      <Navbar bg="light" expand="lg" fixed="top" className="p-1">
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <img
                src={logo}
                alt="Investify Logo"
                style={{ width: 250 }}
                className="logo"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto ms-auto">
              <Link className="nav-link" to="how-it-works">
                How it works
              </Link>
              <Link className="nav-link" to="faqs">
                FAQs
              </Link>
              <Link className="nav-link" to="contact">
                Contact
              </Link>
            </Nav>
            <Nav className="justify-content-end">
              <a
                href="https://app.investify.africa/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button
                  className="btn btn-outline-success me-2 mb-2"
                  type="submit"
                >
                  Login
                </button>
              </a>

              <a
                href="https://app.investify.africa/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="btn btn-success" type="submit">
                  Create Account
                </button>
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
