function Steps(props) {
  return (
    <div className="container mb-5 mt-5">
      <div className="row">
        <div className="col-md-3">
          <div
            style={{
              height: 64,
              width: 64,
              borderRadius: 32,
              backgroundColor: "#008080",
              fontSize: 40,
              fontWeight: 600,
              color: "#fff",
            }}
            className="text-center align-middle ms-auto"
          >
            {props.step}
          </div>
        </div>
        <div className="col-md-6">
          <h4 style={{ fontWeight: 700 }}>{props.title}</h4>
          <p>{props.info}</p>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  );
}

export default Steps;
