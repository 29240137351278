import Steps from "./cards/Steps";
import human from "../assets/humancharacter.png";

function HowItWorks() {
  return (
    <>
      <div className="container pb-5 title-holder hworks">
        <div className="row">
          <div
            className="col-md-6 text-start mt-auto mb-auto"
            style={{ marginBottom: 100 }}
          >
            <h1
              className="h1 "
              style={{ fontWeight: 700, fontStyle: "semi-bold", fontSize: 50 }}
            >
              Invest in Income-Generating Real-Estate Asset in shares
            </h1>
            <p className="font-weight-normal text-muted pb-3">
              The purpose of Investify Africa is to afford investors the
              opportunity to invest in real properties in shares and also enjoy
              the benefits accrued from the investment, either through cash
              flows or capital gains.
            </p>
          </div>
          <div className="text-center col-md-6 pb-5 pt-5">
            <img
              src={human}
              alt="Investify Mobile"
              className="img-fluid"
              style={{ width: 600 }}
            />
          </div>
        </div>

        {/* Steps to Start Using App */}
        <Steps
          step="1"
          title="Register"
          info="Register to become a member of Investify Africa Community with no registration fee"
        />
        <Steps
          step="2"
          title="Explore"
          info="Browse through different Real Estate Projects, find the one that suits you. Choose from our different categories of Real Estate Projects."
        />
        <Steps
          step="3"
          title="Invest"
          info="Choose between capital gains or cashflow, whichever way you want your investment, Investify Africa got you covered."
        />
        <Steps
          step="4"
          title="Monitor"
          info="Watch how your investment grow from your Investify Dashboard"
        />
        <Steps
          step="5"
          title="Earn"
          info="Earn Cashflow or Sell off any of your asset for capital gains"
        />
      </div>
    </>
  );
}

export default HowItWorks;
