import { FiMail, FiMap, FiPhoneCall } from "react-icons/fi";
import Sequence from "./cards/Sequence";

function Contact() {
  return (
    <>
      <div className="container pt-5 pb-5">
        <div className="row">
          <div className="col-md-5 col-sm-12 text-center mt-auto mb-auto title-holder">
            <h2 style={{ fontWeight: 700 }}>Reach Out to Us</h2>
            <p>We are available to answer all your questions</p>
            <Sequence
              icon={<FiMail />}
              title="Email"
              para="admin@investify.africa"
            />
            <Sequence
              icon={<FiPhoneCall />}
              title="Phone"
              para="+234 814 806 0 806"
            />
            <Sequence
              icon={<FiMap />}
              title="Address"
              para="No 19, olumide Odusola street, awoyaya, ibeju lekki, Lagos"
            />
          </div>
          <div className="col-md-7 col-sm-12 pe-4 ps-4">
            <form
              style={{ backgroundColor: "#fff", padding: 30 }}
              className="mt-5 mb-5"
            >
              <div className="text-center mb-4">
                <h3 style={{ fontWeight: 700 }}>Send us a Message</h3>
                <p>Send suggestions and also ask questions</p>
              </div>
              <div className="mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Name
                </label>
                <input className="form-control rounded-pill" type="text" />
              </div>
              <div className="mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Email Address
                </label>
                <input className="form-control rounded-pill" type="email" />
              </div>
              <div className="mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Phone Number
                </label>
                <input className="form-control rounded-pill" type="number" />
              </div>
              <div className="mb-3">
                <label htmlFor="" className="form-label fw-bold">
                  Message
                </label>
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  className="form-control"
                >
                  Type Message
                </textarea>
              </div>
              <button className="btn btn-success">Send Message</button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
