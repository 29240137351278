function Benefits(props) {
  return (
    <>
      <div
        style={{
          height: "100%",
          background: "white",
          padding: 30,
          borderRadius: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            textAlign: "left",
          }}
        >
          <div
            style={{
              padding: 13,
              marginBottom: 28,
              backgroundColor: "#008080",
              borderRadius: 24,
              height: 84,
              width: 84,
              fontSize: 34,
              position: "relative",
              alignItems: "center",
              color: "#fff",
              textAlign: "center",
            }}
          >
            {props.icon}
          </div>
          <h5 style={{ fontSize: 20, fontWeight: "700" }}>{props.title}</h5>
          <p>{props.para}</p>
        </div>
      </div>
    </>
  );
}

export default Benefits;
